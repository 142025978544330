/*
    Created on : 12.3.2014, 12:48:46
    Author     : GEe
    file       : cssReset
*/


@font-face {
    font-family: 'Roboto';
    src: url('../font/Roboto-Regular.woff2') format('woff2'),
         url('../font/Roboto-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

* {
    border: 0;
    font-family: Roboto, sans-serif;
    font-size: 100%;
    margin: 0;
    padding: 0;
    text-indent: 0;
    text-align: left;
    text-decoration: none;
    outline: 0;
}


sup {
    vertical-align: super;
    font-size: 65%;
}

html, body{
   color: #000;
   font-size: @defaultFontSize;
   height: 100%;
   width: 100%;
   overflow:hidden;
}

br{
   line-height: 16px;
}

ol, ul{
    list-style: none;
}

table{
    border-collapse: collapse;
    border-spacing: 0;
}

a {
    color: #000;
    cursor: pointer;
}

::-ms-clear {
  display: none;
}

/* target only to IE9>*/
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
/* IE10+ CSS styles go here */
  body{
    border-top: @colDarkGrey solid 1px;
  }
}
