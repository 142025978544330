/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 28.4.2015, 13:07:09
    Author     : Vojtěch Beneš <vojtech.benes@clevermaps.cz>
*/
@import "mixins.less";

.attachments {
    @fileLineHeight: 16px;
    @wrapDivWidth: 280px;
    @listItemsHorizontalBorderLine: 1px solid @grayBorder;


    &.fr {
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        width: 100%;
    }

    .tooltip{
      ul {
        margin-top: 0px;
      }

      li, li:last-child {
        border: none;
      }
    }

    ul {
        margin-top: 15px;
        li {
            padding: 8px;
            border-top: @listItemsHorizontalBorderLine;
            display: flex;

            div.flex-line{
              flex-flow: row nowrap;
              display: flex;
              width:100%;
            }

            .text-col{
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              display: inline-block;
              flex:1 0 auto;
              line-height: @fileLineHeight;
            }

            &:last-child {
                border-bottom: @listItemsHorizontalBorderLine;
            }

            i.fa {
                margin-right: 5px;
                line-height: @fileLineHeight;
            }

            .flex-icon{
              flex:1 0 auto;
              max-width: 15px;
              flex-basis: auto;
              flex-grow: 1;
            }
        }
    }

    div.edit {
        padding-bottom: 15px;
    }
}
