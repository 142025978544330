.helpCircle{
  a.button {
    padding: 0;
    border-radius: 0;
  }
  i.fa-question {
    /*background: @colLightBlue;
    color: white;*/
    width: 30px;
    height: 30px;
    text-align: center;
    font-size: 30px;
  }
}


.cm-help-page .cm-help{
  position: fixed;
  z-index: 100;
  bottom: 30px;
  right: 30px;

  .helpCircle();
}

.cm-help-modal .cm-help{
  position: absolute;
  bottom: 10px;
  right: 10px;

  .helpCircle();
}

.cm-help {
  .bordered();
  .drop-shadow();

  &.cm-help-admin{
    padding-right: 10px;
    background: @green;
    span.clickable {
      color: white;
      display: inline-block;
      line-height: 30px;
      vertical-align: bottom;
      padding:0 7px;
    }

    &.cm-help-empty{
      background: @red;
      i.fa-question {
          background: @colLightGray;
          color: @colSilverChalice;
      }
    }
  }
}
