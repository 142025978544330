/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 25.8.2014, 11:35:20
    Author     : Vojtěch Beneš <vojtech.benes@clevermaps.cz>
*/


@keyframes blinker {
  50% {
      color: #aaa;
  }
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}

div#top-bar:not(.big-bar) {
  flex: 1 0 auto;
  max-height: 40px;
  border-bottom: 1px solid #eeeeee;
  height: 40px;
  position: relative;

  &.test {
    background-color: #ffccba;
  }

  .blink-me {
    animation: blinker 2s linear infinite;
  }

  a {
    line-height: normal;

    &.content-loading {
      .fa:before {
        content: '';
      }

      > div.loading-spinner {
        margin-left: 0;
      }
    }
  }

  > div:not(.select-project) {
    line-height: 40px;
    padding-left: 20px;
    padding-right: 20px;

    &.logo {
      padding: 0;
    }
  }

  .logo {
    float: left;
    position: relative;

    .image-container {
      float: right;
      display: block;
      padding: 5px 5px;
      text-align: left;
      width: 200px;
      height: 40px;

      img {
        height: 18px;
      }
    }

    a {
      color: @colDimGray;

      &:hover {
        color: @colLightBlue;
      }
    }

    .menu-indicator {
      display: inline-block;
      line-height: 40px;
      font-weight: normal;
      font-size: 20px;
      vertical-align: middle;

      i {
        width: 50px;

        &:before {
          display: block;
          text-align: center;
        }
      }
    }
  }

  .right {
    float: right;

    i.fa-bell, i.fa-cog {
      font-size: 16px;
    }

    i.fa-user {
      margin-right: 5px;
    }

    &.count-with-warning span.number-plate {
      &.green {
        background-color: #17930d;
      }

      &.red {
        background-color: #dc0000;
      }

      &.blue {
        background-color: #0096dc;
      }

      float: right;
      display: block;
      border-radius: 3px;
      color: #fff;
      padding: 0 5px;
      margin-left: -7px;
      margin-top: 2px;
      line-height: 19px;
      font-size: 0.9em;
    }

    &.add-project, &.remove-project, &.edit-project {
      border-left-width: 0;
      padding-right: 0;
    }

    &.add-project {
      padding-left: 8px;
    }

    &.edit-project, &.remove-project {
      padding-left: 5px;
    }
  }

  .left {
    float: left;

    &.search {
      display: none;

      input {
        padding-right: 25px;
        width: 200px;
      }

      i.fa-search {
        margin-left: -20px;
      }
    }

    &.project-name {
      display: none;
      min-width: 170px;
      color: @colBaseFill;
      font-weight: bold;
    }
  }
}

div#top-bar.big-bar {
  flex: 1 0 auto;
  max-height: 80px;
  height: 80px;
  border-bottom: 1px solid #eeeeee;
  position: relative;

  &.test {
    background-color: #ffccba;
  }

  .blink-me {
    animation: blinker 2s linear infinite;
  }

  a {
    line-height: normal;

    &.content-loading {
      .fa:before {
        content: '';
      }

      > div.loading-spinner {
        margin-left: 0;
      }
    }
  }

  .top-bar-container {
    line-height: 80px;
    height: 80px;

    &.top-bar-container__main {
      line-height: 20px;
      max-width: 350px;
      display: flex;
      align-items: center;

      span {
        white-space: nowrap;
      }

      @media (min-width: 1500px) {
        max-width: 420px;
      }

      @media (min-width: 1600px) {
        max-width: 520px;
      }

      @media (min-width: 1700px) {
        max-width: 660px;
      }

      @media (min-width: 1800px) {
        max-width: 750px;
      }

      @media (min-width: 1900px) {
        max-width: 840px;
      }

      @media (min-width: 2000px) {
        max-width: 1000px;
      }
    }
  }

  .top-bar-container-padding {
    padding-left: 10px;
    padding-right: 10px;
  }

  .right {
    float: right;
  }

  .left {
    float: left;
  }

  .logo {
    padding-right: 10px;

    img {
      height: 70px;
      padding-top: 15px;
    }
  }

  .header {
    padding-top: 15px;
    padding-right: 10px;
    line-height: normal;

    &.without-breadcrumbs {
      padding-top: 25px;
    }

    .header-title {
      color: @colLightBlue;
      font-size: 2em;
      text-transform: uppercase;
      font-weight: bold;
      margin-bottom: 5px;
    }

    a {
      text-decoration: underline;
    }
  }

  .menu-indicator {
    display: inline-block;
    line-height: 40px;
    font-weight: normal;
    font-size: 20px;
    vertical-align: middle;

    i {
      width: 50px;

      &:before {
        display: block;
        text-align: center;
      }
    }
  }

  .count-with-warning {
    padding: 20px 10px;
    line-height: 40px;
    height: 40px;

    span.number-plate {
      &.green {
        background-color: #17930d;
      }

      &.red {
        background-color: #dc0000;
      }

      &.blue {
        background-color: #0096dc;
      }

      float: right;
      display: block;
      border-radius: 3px;
      color: #fff;
      padding: 0 5px;
      margin-left: -7px;
      margin-top: 2px;
      line-height: 19px;
      font-size: 0.9em;
    }
  }
}

@media print {
    div#top-bar {
        display: none;
    }
}
