/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 30.10.2014, 13:39:08
    Author     : Vojtěch Beneš <vojtech.benes@clevermaps.cz>
*/
@import "mixins.less";


.statistic {
    .search-text {
        width: 270px;
        padding-right: 30px;

        + .fa-times {
            margin-left: -20px;
        }
    }


    .view-button {
        margin-left: 10px;
    }

    .scrollable {
        overflow: hidden;
        width: 100%;
        white-space: nowrap;

        .chart {
            display: inline-block;
            padding-bottom: 10px;

            .bordered;

            div.title {
                color: @colDimGray;
                text-transform: uppercase;
                padding: 10px;
                text-align: center;
                background-color: @colDarkGrey;
                white-space: normal;
            }

            .graph {
                display: inline-block;

                .interval-selection {
                    margin: 20px 0 0px 50px;
                }
            }



            .description {
                display: inline-block;
                vertical-align: top;
                padding: 20px 0 10px;

                .line {
                    color: #a6a6a6;
                    padding: 5px 20px;
                }
            }
        }

        .summary {
            display: inline-block;
            width: 260px;
            padding-bottom: 10px;

            &:not(:last-child) {
                margin-right: 20px;
            }

            .bordered;

            div.title {
                color: @colDimGray;
                text-transform: uppercase;
                padding: 10px;
                text-align: center;
                background-color: @colDarkGrey;
                white-space: normal;

                a span{
                  text-decoration: underline;
                }

                span {
                    display: inline-block;
                    max-width: 210px;
                    vertical-align: bottom;
                }
            }

            .graph {

                svg {
                    transform-origin: 50% 50%;
                    transform: rotate(-90deg) scale(1, -1);

                    circle {
                        stroke-dashoffset: 0;
                        stroke: @colDarkGrey;
                        stroke-width: 12px;

                        &.bar {
                            stroke: @colDimGray;
                        }
                    }
                }
                display: block;
                height: 200px;
                width: 200px;
                margin: 30px auto;
                position: relative;

                .text-content {
                    height: 160px;
                    width: 160px;
                    margin-top: -80px;
                    margin-left: -80px;
                    left: 50%;
                    top: 66%;
                    position: absolute;
                    color: @colDimGray;

                    .percents {
                        display: block;
                        font-size: 4em;
                        text-align: center;

                        sup {
                            font-size: 50%;
                            vertical-align: super;
                            position: absolute;
                            line-height: 175%;
                        }
                    }
                    .description {
                        display: block;
                        text-align: center;
                        text-transform: uppercase;
                    }
                }
            }

            .line {
                color: #a6a6a6;
                padding: 5px 20px;

                * {
                    display: inline-block;
                }

                .label {
                    width: 100px;
                }

                .count-number {
                    color: @colDimGray;
                    text-align: right;
                    width: 70px;
                }

                .percentage {
                    font-style: italic;
                }
            }

            &.total {
                border-color: #0096dc;
                div.title {
                    color: #fff;
                    background-color: #0096dc;
                }

                .graph {
                    svg .bar {
                        stroke: #0096dc;
                    }

                    .text-content {
                        color: #0096dc;
                    }
                }

                .line {
                    color: #0096dc;

                    .count-number {
                        color: #0096dc;
                    }
                }

                a:not(:hover) {
                    color: #ffffff;
                }
            }
        }
    }
}

.d3-tip {
    background-color: @colDarkGrey;
    color: @colDimGray;
    border-radius: @defaultBorderRadius;
    border-width: 0;
    padding: 10px 15px;
    box-shadow: 0 6px 5px 0 rgba(0,0,0,0.2);
    -webkit-box-shadow: 0 6px 5px 0 rgba(0,0,0,0.2);
}
