/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 25.8.2014, 13:42:15
    Author     : Vojtěch Beneš <vojtech.benes@clevermaps.cz>
*/
@import "mixins.less";

div#content {
    #map-content {
        overflow: hidden;
        position: relative
    }

    .group-title {
        cursor: pointer;
    }

    .switch {

        cursor: pointer;

        .selector {
            display: inline-block;
            width: 25px;

            .indicator {
                display: inline-block;
                box-sizing: border-box;
                width: 16px;
                height: 16px;
            }

        }
    }

    #layer-tooltip {
        z-index: 50;
        display: block;
        height: 26px;
        width: 0px;
        position: absolute;

        .tooltip {
            z-index: 50;
            min-width: 50px;
            white-space: nowrap;
        }
    }

    .map-layer-selector {
        border-right: 1px solid #f1f1f1;
        float:left;
        width: 250px;
        height: 100%;

        h1 {
            padding: 10px;
        }

        .toggle-layer-selector {
            float:right;
            margin: 15px 0 15px 0;
            padding: 2px 7px 2px 7px;
            font-size: 1.3em;
            line-height: 1.3em;
            border-radius: @defaultBorderRadius 0 0 @defaultBorderRadius;
        }

        .group {
            border-top: 1px solid #f1f1f1;
            margin: 6px 0;
            padding: 20px 0px;

            .group-title {
                position: absolute;
                margin: -30px 0 15px 15px;
                text-transform: uppercase;
                color: #c0c0c0;
                background-color: #fff;
                display: block;
                padding: 0 5px;
            }
        }

        div#ls-search {
            a.fa-times {
                position: absolute;
                margin-top: 7px;
                right: 27px;
                color: #000;
            }

            input {
                width: 100%;
                padding-right: 45px;
                border-radius: 0;
            }
            ul.results {
                margin-top: 7px;

                li {
                    div {
                        padding: 4px 15px;
                        display: block;

                        &:hover{
                            background-color: @colHoverItem;
                            color: #0096dc;
                            cursor: pointer;
                        }
                    }
                }
            }
            div.count {
                text-align: center;
            }
            div.switch-wrapper {
                div.gmt-switch {
                    margin: 7px 0;

                    ul {
                      align-items: center;
                      display: flex;
                      justify-content: center;
                      overflow: visible;
                    }
                }
            }

            div.load-next {
                text-align: right;
                padding: 5px 20px 0 0;
            }
        }

        #ls-selector {
            display: block;
            position: relative;

            .switch {
                margin: 0;
                padding: 4px 15px;

                &:hover{
                    background-color: @colHoverItem;
                    color: #0096dc;
                    cursor: pointer;
                }

                .selector {
                    position: absolute;
                    top: 5px;

                    span.fa.fa-plus {
                        margin-left: 2px;
                        margin-top: 2px;
                        color: #ddd;
                    }
                }
            }

            li {
                span {
                    &.img {
                        display: inline-block;
                    }

                    &.title {
                        display: inline-block;
                        margin-left: 25px;
                    }
                }
            }
        }

        &.collapsed {
            width: 40px;
        }
    }
    #map-window {
        height: 0px;
        margin-left: 250px;
        overflow: hidden;

        #main-map {
            .button-state {
               text-align: center;

               span {
                display: inline;
                vertical-align: middle;
                line-height: normal;
              }
            }
            .map-home-icon {
                font-size: 1.35em;
                color: #464646;
            }
            .cuzk.grayscale {
                background-image: url(../img/cuzk-grayscale.png);
            }
        }

        .leaflet-container {
            background: #fff;
        }

        // START FIX because leaflet detect some browsers as touch devide
        //we must override touch classes
        .leaflet-bar a,
        .leaflet-bar button {
          width: 26px;
          height: 26px;
          line-height: 26px;
        }

        .leaflet-draw-toolbar .leaflet-draw-draw-polyline {
          background-position: -2px -2px;
        }

        .leaflet-control-zoom-in, .leaflet-control-zoom-out {
          font-size: 18px;
        }

        .leaflet-bar a:first-child, .leaflet-bar button:first-child {
          border-top-left-radius: @defaultBorderRadius;
          border-top-right-radius: @defaultBorderRadius;
        }
        .leaflet-bar a:last-child, .leaflet-bar button:last-child {
          border-bottom-left-radius: @defaultBorderRadius;
          border-bottom-right-radius: @defaultBorderRadius;
        }

        .leaflet-draw-toolbar .leaflet-draw-draw-polygon {
          background-position: -31px -2px;
        }
        //END fix touch
    }

    .selector-collapsed {
        .group-title, .title, .group-collapsed, #ls-header, #ls-search {
            display: none !important;
        }

        .map-layer-selector {
            width: 45px;
        }

        #map-window {
            margin-left: 45px;
        }

        .switch {
            height: 23px;
        }

        .group {
            padding: 5px 0 0;
            margin: 0 0 5px;
        }
    }
}

@media print {
    div#content {
        .map-layer-selector {
            display: none;
        }

        #map-window {
            margin: 0;

            .leaflet-container {
                background: none;
            }
        }

        .leaflet-control-container {
            display: none;

            a {
              text-decoration: none;
            }
        }
    }
}
