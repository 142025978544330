/*
    Created on : 28.3.2017, 13:39:17
    Author     : Vojtěch Dubrovský <vojtech.dubrovsky@clevermaps.cz>
*/
@import "mixins.less";


div#content {
    .tasks {
        padding: 30px;

        .home-actions {
            div {
                padding: 5px 0;
            }

            a {
                min-width: 300px;

                i {
                    margin-right: 10px;
                }
            }
        }
    }
}
