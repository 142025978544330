/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
 */
/*
Created on : 25.8.2014, 11:01:38
Author     : Vojtěch Beneš <vojtech.benes@clevermaps.cz>
 */
@import "mixins.less";

@blue: #187ab9;
@white: #fff;

//login-component {
//  flex: 1 1 100%;
//}

.login-wrap {
  width: 100%;
  height: 100%;
  overflow: auto;
  position: relative;
}

.login-background {
  height: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  background-color: @white;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background 0.5s linear;

  &.with-image {
    background-image: url(../img/loginpage/background.jpg);
  }
}

.login {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 500px;
  width: 500px;
  color: @blue;
}

.login-group {
  position: relative;
  text-align: center;
}

.login-form {
  width: 350px;
  margin-left: auto;
  margin-right: auto;
}

.login-help {
  float: right;
}

.login-input[type=text],
.login-input[type=password] {
  font-size: 18px;
  padding: 10px 10px 10px 5px;
  -webkit-appearance: none;
  display: block;
  background: transparent;
  width: 100% !important;
  border: none !important;
  border-radius: 0;
  color: @blue;
  box-shadow: none;

  &:focus {
    background: transparent;
    outline: none;
  }

  // Placeholder
  &::-webkit-input-placeholder {
    color: @blue;
  }
  &::-moz-placeholder {
    color: @blue;
  }
  &:-moz-placeholder {
    color: @blue;
  }
  &::placeholder {
    color: @blue;
  }
  &:-ms-input-placeholder {
    color: @blue;
  }
  &::-ms-input-placeholder {
    color: @blue;
  }

  // Autofill
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px white inset !important;
    -webkit-text-fill-color: @blue !important;
    .transition(background-color 5000s ease-in-out 0s);
  }

  // Box shadow
  &:-moz-placeholder,
  &:focus,
  &:invalid {
    box-shadow:none !important;
  }
}

.login-bar {
  &:before {
    left: 0;
    right: 0;
    bottom: 0;
    height: 1px;
    content: "";
    position: absolute;
    pointer-events: none;
    background-color: @blue;
  }
  &:after {
    left: 0;
    right: 0;
    bottom: 0;
    height: 2px;
    content: "";
    position: absolute;
    transform: scaleX(0);
    transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
    pointer-events: none;
    background-color: @blue;
  }
  &.focus:after {
    transform: scaleX(1);
  }
}

.login-button {
  display: inline-block;
  padding-top: 13px;
  padding-bottom: 12px;
  width: 250px;
  font-size: 16px;
  line-height: 20px;
  color: @white;
  background-color: @blue;
  text-align: center;
  border: 0;
  text-transform: uppercase;
  cursor: pointer;
  .transition(background-color 300ms ease-in-out);
  .border-radius(30px);

  &:focus, &:hover {
    outline: 0;
    background-color: @blue * 1.2;
    .transition(background-color 300ms ease-in-out);
  }

  &::-moz-focus-inner {
    border: 0;
  }

  &:disabled {
    cursor: default;
    background-color: fadeout(@blue, 50%);
    .transition(background-color 300ms ease-in-out);
  }
}

a:hover, a:focus {
  color: @blue * 1.4;
}
