/* ==========================================================================
   $BASE-PICKER
   ========================================================================== */
/**
 * Note: the root picker element should *NOT* be styled more than what’s here.
 */
.picker {
  font-size: 12px;
  text-align: left;
  line-height: 1.2;
  color: #000000;
  position: absolute;
  z-index: 10000;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: none;
}

.picker.picker--opened {
  display: block;
}
/**
 * The picker input element.
 */
.picker__input {
  cursor: default;
}
/**
 * When the picker is opened, the input element is “activated”.
 */
.picker__input.picker__input--active {
  border-color: #0089ec;
}
/**
 * The holder is the only “scrollable” top-level container element.
 */
.picker__holder {
  width: 100%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

/*!
 * Classic picker styling for pickadate.js
 * Demo: http://amsul.github.io/pickadate.js
 */
/**
 * Note: the root picker element should *NOT* be styled more than what’s here.
 */
.picker {}
/**
 * The holder is the base of the picker.
 */
.picker__holder {
  /* position: absolute; */
  background: #ffffff;
  border: 1px solid #eeeeee;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  width: 200px;
  /* max-height: 0; */
  -moz-opacity: 0;
  opacity: 0;
}
/**
 * The frame and wrap work together to ensure that
 * clicks within the picker don’t reach the holder.
 */
.picker__frame {
  padding: 1px;
}
.picker__wrap {
  margin: -1px;
}
/**
 * When the picker opens...
 */
.picker--opened .picker__holder {
  max-height: 25em;
  -moz-opacity: 1;
  opacity: 1;
  border-top-width: 1px;
  border-bottom-width: 1px;
  box-shadow: 0 6px 5px 0 rgba(0,0,0,0.2);
  -webkit-box-shadow: 0 6px 5px 0 rgba(0,0,0,0.2);
}
