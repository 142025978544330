.tag {
  align-items: center;
  color: @white;
  display: inline-block;
  padding: 5px 5px;
}

.tag--small {
  height: 25px;
  font-size: 0.8rem;
  line-height: 25px;
  padding: 0px 5px;
}

.tag--inline {
  height: 16px;
  font-size: 0.8rem;
  line-height: 16px;
  padding: 0px 5px;
}

.tag--inactive {
  background-color: @colSilverChalice;
}

.tag--active {
  background-color: @green;

  > div::after {
    content: "\F00D";
  }
}

.tag--danger {
  background-color: @colDanger;
}

.tag--warning {
  background-color: orange;
}


.tag--not-clickable {
  cursor: auto;
}
