.jumbotron {
  padding: @jumbotron-padding (@jumbotron-padding / 2);
  margin-bottom: @jumbotron-padding;
  background-color: @jumbotron-bg;
  border-radius: @border-radius-lg;

  media-breakpoint-up(sm) {
    padding: (@jumbotron-padding * 2) @jumbotron-padding;
  }
}

.jumbotron-fluid {
  padding-right: 0;
  padding-left: 0;
  border-radius: 0;
}
