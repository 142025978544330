new-construction-object-component{
  display: flex;
}

new-construction-object-select-component{
  .flex1();
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  margin: 26px;
  padding: 4px;
}
