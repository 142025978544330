/* colors */
$colWhite: #fff;
$colBlack: #000;
$colRed: #dc3545;
$colGreen: #22b14c;
$colGainsboro: #dcdcdc;
$colLightGray: #f2f2f2;
$colSilver: #909090;
$colGrey: #eeeeee;
$colSilverChalice: #a3a3a3;
$grayBorder: #dddddd;
$colLightBlue: #0096dc;
$colBlue: #13518e;
$colLightYellow: #fbfbdb;
$colYellow: #ffe059;

/* product colors */
$SYMAPBlue: #1a427b;
$SYMAPLightBlue: #57bdf1;
$SYMAPOrange: #ff8700;
$SYMAPGreen: #00c814;
$SYMAPViolet: #7d1e82;
$SYMAPGray: #939493;

$CleverGrayDark: #3d3d3d;
$CleverGrayLight: #e7e7e7;

$colAzureBlue: $SYMAPLightBlue;
$colDeepBlue: $SYMAPBlue;
$colDimGray: $CleverGrayDark;
$colDarkGrey: $CleverGrayLight;

$colBaseFill: $colDeepBlue;
$colHoverItem: $colLightYellow;
$colMenuActiveApp: $SYMAPLightBlue;

/* sizes */
$defaultFontSize: .9rem;
$defaultBorderRadius: 0px;
$defaultTablesBorderRadius: 0px;

/* Project states */
$created: #ffa011;
$startedComplete: #19bb27;
$startedPartial: #01fed8;
$paused: #203864;
$finished: #a8a8a8;

/* Progress status */
$onhold: #ffc90e;
$progress: #30a2e8;
$done: #22b14c;
