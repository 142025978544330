/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 25.8.2014, 10:09:28
    Author     : Vojtěch Beneš <vojtech.benes@clevermaps.cz>
*/
@import "mixins.less";


h1 {
    color: @colBaseFill;
    font-size: 2.5em;
    font-weight: normal;

    span {
        font-size: 0.4em;
        display: inline-block;
        vertical-align: super;
        text-transform: uppercase;
    }
}

h2 {
    padding: 10px 0;
    color: @colBaseFill;
    font-size: 1.3em;
    font-weight: normal;
}

.header-subtitle {
  color: @colBaseFill;
  font-size: 1.2em;
  display: block;
  margin-top: 1rem;
}

p {
    margin-top: 0;
    margin-bottom: 1rem;
}

.app {
  //display: flex;
  height: 100%;
  //flex-direction: column;
  //box-sizing: border-box;

  .main{
    display: flex;
    flex-direction: row;
    flex: 1 1 100%;
    min-height: 0px;
  }

  main-ctrl, symap, dimap, promap-main-ctrl, settings-main-ctrl, ng-component {
    display: flex;
    height: 100%;
    flex-direction: column;
    box-sizing: border-box;
  }
}

.empty-info {
    background: @colLightGray;
    padding: 10px;
}

.line-height-l {
  line-height: 24px;
}

.cb {
    clear: both;
}

.fr {
    float: right;
}

.fl {
    float: left;
}

.ib {
    display: inline-block;
}

.ml-4 {
    margin-left: 4px;
}

.pt-7 {
    padding-top: 7px;
}

.p-5 {
  padding: 5px;
}

.p-10 {
  padding: 10px;
}

.p-20 {
  padding: 20px;
}

.no-padding{
  padding: 0 !important;
}

span.unit {
    text-transform: none;
}

fieldset, div.fieldset {
    position: relative;
    margin-top: 40px;
    padding: 20px;
    min-width: 0;
    background: @colLightGray;
    margin-bottom: 20px;

    &.no-padding{
      padding: 0px;
    }

    &.no-legend{
      margin-top: 0px;
    }

    >.on-border{
      position: absolute;
      top: -10px;
    }

    &.bordered{
      background: #FFFFFF;
    }

    &.bordered-top{
      background: #FFFFFF;
      .bordered-top;
    }

    legend, div.legend {
        position: absolute;
        color: @colDeepBlue;
        left: 0;
        font-size: 15px;
        top: -25px;
    }
}

a {
    color: @colLightBlue;

    &[ui-sref], &[uisref], &[href]:not([href^="#"]) {
      &:not(.not-underline):not(.button):not(.nav-link):not(.row) {
        text-decoration: underline;
      }
    }
    &[ui-sref].row, &[uisref].row,
    &[ui-sref].nav-link, &[uisref].nav-link{
      text-decoration: none;
    }

    &:hover {
        color: @colDimGray;
    }
}

.comma-list {
    &>*:not(:last-child):after {
      content: ', ';
      .inline-after();
    }
    &>*:nth-last-child(2):after {
      content: ' a ';
      .inline-after();
      //padding-left: 1ch;
    }
}

.underlined {
  text-decoration: underline;
}

.inline-after{
  vertical-align: text-top;
  text-decoration: none;
  display: inline-block;
  overflow: hidden;
  //padding-right: 1ch;
  white-space: pre;
}

div.column-25 {
    width: 25%;
}

div.column-33 {
    width: 33%;
}

div.column-50 {
    width: 50%;
}

div.column-66 {
  width: 66%;
}

div.column-75 {
    width: 75%;
}

div.column-100 {
    width: 100%;
}

div.column, div.column-25, div.column-33, div.column-50, div.column-66 , div.column-75, div.column-100 {
    overflow: auto;
    float: left;
    box-sizing: border-box;

    label, div.label {
        clear: both;
        float: left;
        color: @colSilver;
        width: 200px;
        padding: 7px 10px 7px 0;

        &.w-250 {
          width: 250px;
        }

        &.w-300 {
            width: 300px;
        }
    }

    div.info {
        float: left;
        padding: 7px 0;

        &.fn {
            float: none;
        }
    }

    div.input {
        float: left;
        padding: 2px 10px 2px 0;
    }
}

.empty:after {
    content: 'nezadáno';
    font-style: italic;
}

span.date-selector {
    display: inline-block;

    i {
        margin-left: -20px;
        margin-top: 7px;
        position: absolute;
        pointer-events: none;
        color: @colLightBlue;
    }
}

.file-selector {
    cursor: pointer;
    display: inline-block;
}

.file-upload {
    .error {
      .error-message()
    }

    .progressbar {
        background-color: #eee;
        border-radius: 0px;
        padding: 3px;
        margin-top: 10px
    }

    .progressbar > div {
        background-color: @colLightBlue;
        height: 5px;
        border-radius: 0px;
    }
}

.clearfix {
    overflow: auto;
}

.clickable {
    cursor: pointer;
}

.padding-10 {
  padding: 10px;
}

.padding-20 {
  padding: 20px;
}

.checklist {
    .checklist-item {
        padding: 8px;

        &.with-action {
            padding: 1px 8px;
        }

        &:not(:last-child):not(.unborder){
            border-bottom: 1px solid @grayBorder;
        }

        &:hover {
            background-color: @colHoverItem;
            cursor: pointer;

            fieldset, legend {
                background-color: @colHoverItem;
            }
        }
    }
}

.preview-list {
    .preview-list-item {
        padding: 8px;

        &:not(:last-child) {
            border-bottom: 1px solid @grayBorder;
        }

        &:hover {
            background-color: @colHoverItem;

            fieldset, legend {
                background-color: @colHoverItem;
            }
        }
    }
}

.cancelled {
    opacity: 0.5;
}

@media print {
    div.scrollable {
        overflow: visible;
    }

    div.scrollable[style] {
        height: auto !important;
    }
}

.disabled {
  .disable();

  a{
    .disable();
  }
}

.full-width{
  width: 100%;
}

.flexbox-container {
    display: flex;

    > div {
       &.column-25, &.column-33, &.column-50, &.column-75, &.column-100 {
            overflow: visible;
            float: none;
       }
    }
}

.flex-column {
  flex-direction: column;
}

.flex1{
  flex: 1 0 auto;

  &.basis0 {
      flex-basis: 0;
  }
}

.flex-rest {
  flex: 1 1 auto
};

.flex-centered {
  vertical-align: middle;
  justify-content: center;
  align-items: center;
}

.flex-vertical-centered {
  align-items: center;
}

.centered-text{
  text-align: center;
}

.cm-select-placeholder{
  flex: 1;
}

.top {
    vertical-align: top;
};

.clickable {
    cursor: hand;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-uppercase {
  text-transform: uppercase;
}

.gmt-scrollable {
  overflow: auto;

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: @colGrey;
  }

  &::-webkit-scrollbar-thumb {
    background-color: @colSilverChalice;
    border-radius: 10px;

    &:hover {
      background-color: @colSilver;
    }
  }
}

.txt--blue {
  color: @colDeepBlue !important;
}

.txt--muted {
  color: @colSilver !important;
}


.module-wrapper {
  height: 100%;
}
