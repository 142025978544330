/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 7.5.2015, 15:52:05
    Author     : Vojtěch Beneš <vojtech.benes@clevermaps.cz>
*/

@import "mixins.less";

div#content {
    .authorization-item {

        &:not(:last-child) {
            margin-bottom: 10px;
            padding-bottom: 10px;
            border-bottom: 1px solid @grayBorder;
        }
    }
}
