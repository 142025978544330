.cmUploader {
	padding: 50px 20px !important;
	min-height: 200px;
}

.cmFilesPairs {
	line-height: 25px;
	padding: 7px 10px;
}

span.add-file {
	color: @colDeepBlue;
	cursor: pointer;

	&:hover {
		color: @colBlue;
	}
}

.box {
	display: flex;
	font-size: 1.25rem; /* 20 */
	background-color: fadeout(@colBlue, 80%);
	position: relative;
	padding: 100px 20px;

	.content {
		width: 100%;
		color: #13518e;
		text-align: center;
		min-height:200px;
	}
}

.box.has-advanced-upload {
	outline: 2px dashed @colBlue;
	outline-offset: -10px;
	-webkit-transition: outline-offset .15s ease-in-out, background-color .15s linear;
	transition: outline-offset .15s ease-in-out, background-color .15s linear;
}


.box.dragover {
	outline: 4px dashed @colDeepBlue;
}

.box.has-advanced-upload .box__icon {
	width: 100%;
	height: 80px;
	fill: @colBlue;
	display: block;
	margin-bottom: 40px;
	cursor: pointer;
}

.box__input {
	width: 100%;
	display: flex;
	flex-direction: column;

	.flex-rest {
		align-items: flex-end;
		margin-top: 20px;
		display: flex;
		justify-content: center;
	}

	label {
		color: @colBlue;
		text-align: center;

		strong {
			cursor: pointer;
		}
	}

	.cmUploaderFiles {
		margin: 20px 0px;
		text-align: center;
		font-size: 15px;
	}
}
