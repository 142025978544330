

.cm-map-infobox {
  display: flex;
  .bordered;
  max-width: 450px;
  min-width: 200px;
  // min-height: 200px;
  background: #ffffff;
  position: absolute;
  top: 50px;
  right: 20px;
  z-index: 10;
  box-shadow: 0 6px 5px 0 rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 6px 5px 0 rgba(0, 0, 0, 0.2);

  label {
    width: 120px !important;
  }

  .centered{
    height: 100%;
    display: block;
    align-items: center;
    justify-content: center;


    .centered-text{
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    p {
      margin: 0;
    }
  }

  .content-layers{
    margin-right: 5px;

    .layer-item{
      display: flex;
      flex-direction: column;

      &:not(:last-child) {
        padding-bottom: 8px;
        border-bottom: solid fadeout(@colSilver, 30%) 1px;
      }
    }
  }

  .content{
    width: 100%;
    padding: 6px 12px;
  }


  .popup-close-button {
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0px;
    margin: 10px 15px 0 0;
    line-height: 20px;
    border: none;
    text-align: center;
    font-size: 20px;
    color: @colLightBlue;
    text-decoration: none;
    font-weight: bold;
    background: transparent;

    &:hover {
      color: @colDeepBlue;
    }
  }
}
