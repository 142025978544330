/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 26.8.2014, 16:35:51
    Author     : Vojtěch Beneš <vojtech.benes@clevermaps.cz>
*/
@import "mixins.less";

div#content {
    .statistics {
        padding: 30px;

        // Used for style of templates
        .statistic-line {
            &:first-child {
                .statistic-label {
                    border-top-right-radius: @defaultTablesBorderRadius;
                    border-top-left-radius: @defaultTablesBorderRadius;
                }
            }

            &:last-child:not(.active) {
                .statistic-detail, .statistic-label {
                    border-bottom-width: 1px;
                    border-bottom-right-radius: @defaultTablesBorderRadius;
                    border-bottom-left-radius: @defaultTablesBorderRadius;
                }
            }

            &.active + * {
                .statistic-label {
                    border-top-width: 0px;
                }
            }

            .statistic-label {
                border: 1px solid @grayBorder;
                border-bottom-width: 0px;
                display: inline-block;
                padding: 10px;
                min-width: 410px;
                text-transform: uppercase;
                cursor: pointer;

                &:hover {
                    background-color: @colHoverItem;
                    color: #0096dc;
                }
            }

            .statistic-detail {
                border: 1px solid @grayBorder;
                padding: 20px;
                min-height: 90px;
                border-top-right-radius: @defaultTablesBorderRadius;
                border-bottom-right-radius: @defaultTablesBorderRadius;

                label{
                  cursor: pointer;
                }
            }
        }
    }
}

.stats {
    .nav-item > a.nav-link {
        text-align: center;
        min-width: 140px;
    }

    .nav {
        justify-content: flex-end;
    }

    .tabbable-line.tabs-below > .tab-content {
        margin: 0;
        padding: 0;
        .bordered;
        border-top-width: 0;
        padding-bottom: 0;
        background-color: @colLightGray;

        .category-select .scrollable {
            .scrollable-inner {
                display: inline-block;
                min-width: 100%;
                padding-top: 20px;
                background-color: #fff;
                margin-bottom: 20px;
                .bordered-bottom;

                .count {
                    text-align: center;
                    font-size: 30px;
                    height: 30px;
                    font-weight: bold;
                    margin-bottom: 15px;
                    min-height: auto;
                    min-width: auto;

                    .loader {
                        width: 20px;

                        .loading-spinner {
                            margin: 0;
                            width: 20px;
                            height: 20px;
                            border-width: 3px;
                        }
                    }
                }

                .label {
                    text-align: center;
                }

                .filter {
                    display: inline-block;
                }
            }
        }

        .flex1 {
            min-width: 100px;

            > div {
                color: black;
                display: block;
                max-width: 200px;
                padding: 0px;
                margin: 0 auto;
                height: 100%;
            }

            .category-nav {
                padding: 20px;
                color: black;
                display: block;
                text-decoration: none;
            }

            > .category, > .total-category {
              a.filter-nav, span.filter-nav {
                  position: absolute;
                  margin: 10px 0 0 10px;
                  display: block;
                  font-size: 18px;
              }

              span.filter-nav {
                color: @colSilver;
              }

              &.active {
                a.filter-nav {
                  color: white;

                  &:hover {
                      color: @CleverGrayDark;
                  }
                }

                span.filter-nav {
                  color: @colSilverChalice;
                }
              }
            }

            > .category {
                &:hover {
                    background-color: @colHoverItem;
                }

                &.active {
                    background-color: @colLightBlue;
                    position: relative;

                    &::after {
                        content: "";
                        position: absolute;
                        margin: 0 auto;
                        color: @colLightBlue;
                        left: 50%;
                        bottom: -12px;
                        .translate(-50%);
                        border-left: 10px solid transparent;
                        border-right: 10px solid transparent;
                        border-top: 12px solid #0096dc;
                    }

                    a.category-nav {
                        color: white;
                    }
                }
            }
        }

        .interval {
            .tabbable-line {
                > .nav-tabs {
                    border-bottom-width: 0;
                    margin: 0 5px;

                    > li.nav-item {
                        margin-bottom: 0;

                        > a:not(.active) {
                            background-color: @colDarkGrey;
                        }

                        &:hover > a.active {
                            background-color: #fff !important;
                        }
                    }
                }
            }

            .tab-content {
                background-color: #fff;
                border-width: 0;
            }

            line-chart {
                div {
                    text-align: center;
                    min-height: 330px;
                }
                display: block;
            }

            .interval-data {
                display: block;
                text-align: center;
                padding-top: 10px;
                min-height: 34px;

                .loader {
                    width: 20px;

                    .loading-spinner {
                        margin: 0;
                        width: 20px;
                        height: 20px;
                        border-width: 3px;
                    }
                }

                .interval-change {
                    font-weight: bold;
                    font-size: 20px;
                }

                .interval-trend {
                    position: relative;
                    display: inline-block;
                    vertical-align: top;

                    span {
                        font-size: 11px;

                        &::after {
                            font-size: 14px;
                            font-family: 'FontAwesome';
                            bottom: 0;
                            position: absolute;
                            left: 50%;
                            -webkit-transform: translate(-50%, 9px);
                            -moz-transform: translate(-50%, 9px);
                            -ms-transform: translate(-50%, 9px);
                            transform: translate(-50%, 9px);
                        }

                        &.green::after {
                            color: @green;
                            content: '\F0D8';
                        }

                        &.red::after {
                            color: @red;
                            content: '\F0D7';
                        }
                    }
                }
            }
            // SVG
            svg {
                margin: 0 auto;
                max-width: 100%;
                display: block;
            }

            .line {
                fill: none;
                stroke: @colLightBlue;
                stroke-width: 3;
            }


            .area{
                fill: url(#area-gradient);
              }

            /* Style the dots by assigning a fill and stroke */
            .dot {
                fill: #fff;
                stroke: @colLightBlue;
                stroke-width: 3;
            }

            .dotHover {
                fill: #fff;
                stroke: @colLightBlue;
                stroke-width: 3;
            }

            .domain, .tick line {
                stroke: @colGrey;
            }

            text {
                font-weight: bold;
            }
        }
    }
}
