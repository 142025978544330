/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 5.9.2016, 15:52:08
    Author     : Vojtěch Beneš <vojtech.benes@clevermaps.cz>
*/

@import "mixins.less";

.workflow {
    > div {
        > *:not(:last-child):not(:first-child) {
            .bordered-bottom;
        }
        
        > *:nth-child(2) {
            padding-top: 20px;
        }
    }
    .leave-statuses, .enter-statuses, .documents {
        padding: 5px 10px;
        > * {
            margin: 5px;
            display: inline-block;
        }
        
        > span {
            padding: 5px 0;
        }
    }
    
    .fa-sign-out, .fa-sign-in {
        font-size: 25px;
        float: left;
    }
    
    .fa-file {
        font-size: 20px;
        float: left;
    }
    
    .flow-status {
        padding: 2px 10px 0;
        margin-left: 5px;
        position: absolute;
        margin-top: -10px;
        margin-left: 10px;
        background-color: #fff;
    }
}