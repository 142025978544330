/*
    Created on : 25.8.2014, 11:29:52
    Author     : Vojtěch Beneš <vojtech.benes@clevermaps.cz>
*/


div#content-wrap{
  overflow: auto;
  flex: 1 1 auto;
  display: flex;
  flex-flow: column;
  flex-direction: column;
};


div#content{
  flex: 1 0 auto;
  display: flex;
  position: relative;
  flex-direction: column;

  & > *:not(.cm-help-page) {
    flex: 1 0 auto;
  }

    i.cuzk, span.cuzk {
        padding-left: 16px;
        line-height: 16px;
        background-image: url("../img/cuzk.png");
        background-size: 16px 16px;
        background-repeat: no-repeat;
        //background-position-y: 4px;

        &.small {
            background-size: 12px 12px;
            padding-left: 12px;
            background-position-y: 1px;
        }
    }

    .actions {
        flex-grow: 1;
        margin-bottom: 10px;
        line-height: 24px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-end;

        &.fn {
            float: none;
        }

        &.fn, &.fl {
            text-align:left;

            a {
                margin-left: 0px;
                margin-right: 30px;
            }
        }

        &.fr {
            float: right;
        }

        a {
            text-transform: uppercase;
            margin-left: 30px;
            white-space: nowrap;

            i:not(.fa-stack-1x) {
                margin-right: 10px;
                vertical-align: baseline;
            }
        }
    }

    .content-menu {
        border-right: 1px solid @grayBorder;
        padding-right: 20px;
        min-width: 145px;

        > * {
            padding: 10px;
            text-transform: uppercase;

            &:not(.cancelled) {
              color: @colLightBlue;

              &:not(.active) {
                cursor: pointer;
              }
            }

            &:not(:first-child) {
                border-top: 1px solid @grayBorder;
            }

            &:hover:not(.cancelled), &.active {
                background-color: @colLightBlue;
                color: #fffefe;
            }
        }
    }

    .content-menu + * {
        padding-left: 19px;
        width: 100%;
    }
}

@media print {
    div#content {
        border-left: none;
        padding: 0;
    }
}
