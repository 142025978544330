/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 13.10.2014, 10:35:10
    Author     : Vojtěch Beneš <vojtech.benes@clevermaps.cz>
*/

// @TODO - moved into A7 angular/common/components/tabbed-block

.tabbed-block, .switch {
    position: relative;

    ul.tabs {
        margin-top: -32px;
        right: 20px;
        position: absolute;
        z-index: 9;
    }

    ul.tabs, ul.options {
        overflow: auto;
        li {
            float: left;
            border-width: 0px;
            border-color: @colLightBlue;
            border-style: solid;
            background-color: #fff;

            a {
                color: @colLightBlue;
                text-transform: uppercase;
                padding: 5px 11px;
                display: block;
            }

            &:first-child {
                border-top-left-radius: @defaultBorderRadius;
                border-bottom-left-radius: @defaultBorderRadius;

                &:not(.active) {
                    border-left-width: 1px;

                    a {
                        padding-left: 10px;
                    }
                }
            }

            &:last-child {
                border-top-right-radius: @defaultBorderRadius;
                border-bottom-right-radius: @defaultBorderRadius;

                &:not(.active) {
                    border-right-width: 1px;

                    a {
                        padding-right: 10px;
                    }
                }
            }

            &:hover {
              background-color: @colLightBlue;

              a {
                  color: #fffefe;
              }
            }

            &.active {
                background-color: @colLightBlue;

                a {
                    color: #fffefe;
                }
            }

            &:not(.active) {
                border-top-width: 1px;
                border-bottom-width: 1px;

                a {
                    padding-top: 4px;
                    padding-bottom: 4px;
                }

                & + li:not(.active) {
                    border-left-width: 1px;

                    a {
                        padding-left: 10px;
                    }
                }
            }
        }
    }
}
