/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 8.9.2014, 14:26:07
    Author     : Vojtěch Beneš <vojtech.benes@clevermaps.cz>
*/
@import "mixins.less";

.modal-container {
  display: flex;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1999;
}

.modal {
    max-width: 1000px;
    min-width: 700px;
    height: auto;
    overflow: hidden;
    border-radius: @defaultBorderRadius;
    background-color: #fff;
    width: 70%;
    position: relative;
    .drop-shadow();

    &.modal-no-padding .modal-content .modal-inner-content {
      padding: 0;
    }

    &.modal-no-padding .ps__rail-x,
    &.modal-no-padding .ps__rail-y {
      display: none;
    }

    &.modal-no-padding .close {
      margin-right: 10px;
    }

    .close {
        position: absolute;
        right: 0;
        z-index: 1;
        margin: 10px 25px 0 0;
        background-color: #666;
        display: block;
        color: #fff;
        padding: 7px 8px;
        border-radius: @defaultBorderRadius;
        font-size: 1.5em;
        cursor: pointer;
    }

    .modal-content {

        .modal-inner-content {
            padding: 15px 20px; /*padding must be added to tooltip orientation calculation*/

            h1 {
                text-align: center;
                margin-bottom: 15px;
            }

            .modal-description {
                text-align: center;
            }
        }

        padding: 5px;

        > * > .scrollable{
            min-height: 360px;
        }

        .actions {
            clear: both;
            padding-top: 20px;
            text-align: center;

            input, a.button {
                margin: 10px;
            }
        }
    }

    &.adjusted-dialog, &.unclosable-dialog {
        .modal-content > * > .scrollable{
            min-height: inherit;
        }
    }

    &.higher-modal {

        .modal-content > * > .scrollable{
            min-height: 500px;
        }
    }

    &.wider-modal {
        max-width: 100vw;
    }

    &.case-create-modal {
        .higher-modal;
        max-width: 1000px;

        .modal-content > * > .scrollable{

            .select-obligation {
                text-align: center;
                padding: 20px;

                .cm-select .select-value {
                    width: 300px;
                }
            }

            .price-summary {
                background-color: #f2f2f2;
                border-radius: @defaultBorderRadius;
                float:right;
                padding: 10px;
                font-weight: bold;
            }
        }
    }

    &.parcel-price-update-modal {
        table input[type=text] {
            width: 70px;
        }
        .parcel-price-selected {
            background-color: #f6f6f6;
        }

    }
}
