// @TODO - moved into A7 component angular/components/file-upload and angular/ps/components/documents

.drop-container{
   margin: 0px 4px 16px 4px;

  .drop-border-area{
    width: 100%;
    display: flex;

    .drop-area{
      border: @colSilverChalice dashed 1px;
      padding: 1px;
      width: 100%;
      display: flex;
      flex-flow: column;
      justify-content: space-around;
      align-items: center;

      .drop-infobox{
        text-align: center;
        margin: 10px 20px;

        i{
          color: @colSilverChalice;
        }

        p{
          margin: 10px 0;
        }
      }

      ul{
        min-width: 300px;
        li {
          margin-bottom: 10px;

          .invalid {
            color: @red;
          }
        }
      }

    }
  }
}

.cm-is-dragover .drop-container {
  .drop-area {
    border-width: 2px;
    padding: 0;
    .drop-infobox{
      i {
        color: black;
      }
    }
  }
}

td.attachment-item{
  > div {
    display: flex;

    div:first-child{
      height: 40px;
      display: table;
      vertical-align: middle;
      a{
        vertical-align: middle;
        display: table-cell
      }
    }
  }

  div:nth-child(2) {
    line-height: 20px;
    p {
      margin-bottom: 0px;
    }
  };
}

a.remove-document{
  margin-left: 8px;
  margin-right: 16px;
  color: @colDimGray;
  &:hover{
    color: #000000;
  }
}

.file-upload.attachment{
  >div{
    min-width:200px;

    div.icon{
      height: 45px;
      display: table;
      vertical-align: middle;

      i {
        vertical-align: middle;
        display: table-cell;
      }
    }
  }
}
