/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 26.8.2014, 16:35:51
    Author     : Vojtěch Beneš <vojtech.benes@clevermaps.cz>
*/
@import "mixins.less";

.tag {
  cursor: pointer;
  height: 25px;
  display: flex;
  align-items: center;
  padding: 2px 5px;
  background-color: #a3a3a3;
  color: @white;

  > div::after {
    padding-left: 12px;
    font-family: FontAwesome;
    content: "\F067";
  }

  .content-loading {
    display: inline-block;
  }

  &.active {
    background-color: @green;

    > div::after {
      content: "\F00D";
    }
  }
}

div#content {
    .list {
        padding: 30px;
    }
}
div#content, div.modal-content {
    .list {
        fieldset {
            margin-top: 40px;
            margin-bottom: 20px;
            padding-bottom: 10px;

            .filter-items {
              display: flex;
              flex-wrap: wrap;
              align-items: flex-start;
                >* {
                    margin-bottom: 10px;

                    &:not(:last-child) {
                        margin-right: 15px;
                    }
                }

                .fulltext {
                  font-size: 15px;
                  width: 500px;
                }
            }

            .filter-submit {
                float: right;
                margin-bottom: 10px;
                margin-left: 15px;
            }
        }

        .pagination {
            display: flex;
            justify-content: center;
            margin-top: 20px;
            text-align: center;

            >div{
              line-height: 25px;
              display: inline-flex;
            }

            ul {
                display: inline-block;
                margin: 0 24px;
                height: 25px;

                li {
                    float: left;
                    margin: 0 3px;
                    text-align: center;
                    line-height: 25px;
                    min-width: 30px;

                    a {
                        display: block;
                        height: 25px;
                        line-height: 25px;
                        cursor: pointer;
                        text-align: center;
                    }

                    i {
                        visibility: hidden;
                        display: block;
                        text-align: center;
                        color: #0096dc;
                        line-height: 5px;
                    }

                    &.active {
                        a {
                            background-color: #0096dc;
                            border-color: #0096dc;
                            color: #fff;
                            margin-top: 0px;
                        }

                        i {
                            visibility: visible;
                        }
                    }

                    &.fa-ellipsis-h {
                        margin-top: 5px;
                    }
                }
            }
        }
    }
}
