/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 24.2.2015, 12:45:58
    Author     : Vojtěch Beneš <vojtech.benes@clevermaps.cz>
*/

div#content {
    .exports {
        ul.export-params {
            li {
                margin: 4px 0;
            }
        }
    }
}