input[type=checkbox],
input[type=radio] {
    display: none;
}

input[type=radio]:disabled + span:after,
.cm-input-icon-disabled,
.cm-input-icon-disabled i {
    color: #FF9494;
    cursor: not-allowed;
}

[type="radio"] {
    display: none;
}
[type="radio"] + span {
    position: relative;
    padding-left: 20px;
    cursor: pointer;
    height: 15px;
    display: inline-block;
    color: #666;
}
[type="radio"] + span:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 17px;
    height: 17px;
    border: 1px solid #ddd;
    border-radius: 100%;
    background-color: #fff;
}
[type="radio"] + span:hover:before,
[type="radio"]:checked + span:before {
  background-color: #0096dc;
  border-color: #0096dc;
}
[type="radio"] + span:after {
    content: '';
    width: 9px;
    height: 9px;
    background: #fff;
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 100%;
    transition: all 0.2s ease;
}
[type="radio"]:not(:checked) + span:after {
    opacity: 0;
    transform: scale(0);
}
[type="radio"]:checked + span:after {
    opacity: 1;
    transform: scale(1);
}

input[type=text], input[type=password], textarea {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    color: @colDimGray;
    padding: 4px 9px;
    border: 1px solid @colDarkGrey;

    &:focus{
      background: @colHoverItem;
    }

    &::-webkit-input-placeholder { /* WebKit browsers */
        color:    #8d8d8d;
    }
    &:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
        color:    #8d8d8d;
        opacity:  1;
    }
    &::-moz-placeholder { /* Mozilla Firefox 19+ */
        color:    #8d8d8d;
        opacity:  1;
    }
    &:-ms-input-placeholder { /* Internet Explorer 10+ */
        color:    #8d8d8d;
    }

    &.invalid {
        color: #C2341C;
    }

    &.w-50 {
        width: 50px;
    }

    &.w-60 {
        width: 60px;
    }

    &.w-350 {
        width: 350px;
    }

    &.w-inherit {
        width: inherit;
    }

    &:disabled{
      color: @colSilver;
    }
}

input[type=text], input[type=password] {
    width: 200px;

  &.red {
    border-color: #dc0000;
    color: #dc0000;
  }
}

textarea {
    width: 100%;
    height: 100px;
}

textarea.tall {
    min-height: 300px;
}

input[type=submit], input[type=button], a.button {
    background-color: #fff;
    color: @colLightBlue;
    border: 1px solid @colLightBlue;
    border-radius: @defaultBorderRadius;
    padding: 5px 10px;
    text-transform: uppercase;
    cursor: pointer;
    display: inline-block;
    line-height: normal;

    &.black {
        border-color: @colDimGray;
        color: @colDimGray;
    }

    &.red {
        border-color: #dc0000;
        color: #dc0000;
    }

    &.green {
        border-color: #70B81A;
        color: #70B81A;
    }

    &:hover:not(.disabled), &.active:not(.disabled) {
        border-color: @colLightBlue;
        background-color: @colLightBlue;
        color: #fffefe;
    }

    &.disabled {
        opacity: 0.65;
        cursor: not-allowed;
    }
}

/* CR-2308 cm-button related code start */
cm-button:hover:not(.disabled) .button:not(.disabled) {
    background-color: @colLightBlue;
    color: #fffefe;
}

.black .button {
    border-color: @colDimGray;
    color: @colDimGray;
}

.red .button {
    border-color: #dc0000;
    color: #dc0000;
}

.green .button {
    border-color: #70B81A;
    color: #70B81A;
}

.disabled .button {
    opacity: 0.65;
    cursor: not-allowed;
}

/*.disabled .button:hover {
    border-color: @colLightBlue !important;
    color: @colLightBlue !important;
}*/
/* CR-2308 cm-button related code end */


.input-button {
    margin-left: -25px;
    color: #000;
}

input.button-padding {
    padding-right: 25px;
}

a.checkbox {
    color: @colDimGray;
}

.required::after {
    color: red;
    content: '*';
    padding-left: 5px;
}

