/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 25.8.2014, 12:29:06
    Author     : Vojtěch Beneš <vojtech.benes@clevermaps.cz>
*/

div#bottom-bar {
  clear: both;
  position: absolute;
  z-index: 11;
  bottom: 0px;
  width: 100%;

    .copy {
        margin-top: 10px;
        margin-bottom: 10px;
        color: @colGainsboro;
        width: 100%;
    }

  .versions{
    font-size: 10px;

    a {
      color: @colDeepBlue;
    }
  }
}

@media print {
    div#bottom-bar {
        display: none;
    }
}
