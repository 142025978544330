/*
    Created on : 25.8.2014, 10:09:28
    Author     : Vojtěch Beneš <vojtech.benes@clevermaps.cz>
*/
@import "mixins.less";


.cm-select {
    background-color: #fff;
    border: 1px solid @colDarkGrey;
    border-radius: @defaultBorderRadius;
    cursor: default;
    height: 25px;
    box-sizing: border-box;
    display: inline-block;
    color: #000;
    text-transform: none;
    min-width: 200px;

    .cm-select-inputwrap{
      display: flex;
      height: 100%;
      cursor:pointer;
    }

    a.select-value {
        color: @colDimGray;
        padding: 0px 10px;
        color: @colDimGray;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        white-space: nowrap;
        text-transform: none !important;
        display: flex;
        flex: 1 0 auto;
        align-items: center;
        text-decoration: none;

        &.empty:after {
            content: 'nezvoleno';
        }
    }
    .select-carret {
        flex: 0 0 22px;
        margin-right: 3px;
        width: 22px;
        height: 100%;
        line-height: 25px;
        text-align: center;
        border-top-right-radius: @defaultBorderRadius;
        border-bottom-right-radius: @defaultBorderRadius;
        color: #fff;
        cursor: pointer;
    }

    .arrow{
      top: 3px;
      margin-left: 0px;
      box-sizing: content-box;
      left: 0;
      .transform(rotate(0));

      &:before, &:after {
        background-color: transparent;
        width: 3px;
        height: 8px;
        display: inline-block;
        position: absolute;
        border-bottom: 10px solid @colLightBlue;
        top: 0;
        left: 0;
        .transform(rotate(0));
      }

      &:before{
        .transform(rotate(-135deg));
      }

      &:after {
        .transform(rotate(135deg));
      }

      &.active{
        &.arrow {
          .transform(rotate(0));
          .transform(translate(0, -6px));
        }

        &:before{
          .transform(rotate(-45deg));
        }

        &:after{
          .transform(rotate(45deg));
        }
      }
    }

    .selected-count {
        cursor: default;
        margin-left: auto;
        display: none;
        background-color: #17930d;
        border-radius: 3px;
        color: #fff;
        padding: 0px 5px;
        margin-right: 5px;
        margin-top: 3px;
        margin-bottom: 3px;
        line-height: 18px;

        a {
            color: #fff;
        }
    }
    .options-pane {
        /*&.focused {
            z-index: 13;
        }*/
        position: absolute;
        min-width: 305px;
        box-sizing: content-box;
        background-color: white;
        z-index: 12;
        box-shadow: 0 6px 5px 0 rgba(0,0,0,0.2);
        -webkit-box-shadow: 0 6px 5px 0 rgba(0,0,0,0.2);

        &:not(.multiselect) {
            input[type=checkbox] {
                &:not(:checked) + div::after{
                    content: "";
                }
            }
        }

        input.search-text {
            width: 270px;
            margin: 15px 15px 5px;
            padding-right: 30px;
            float: right;
        }

        div.load-next {
            padding: 15px;
            border-top: 1px solid @grayBorder;
            text-align: right;
        }

        i.fa-search, a.fa-times {
            position: absolute;
            display: block;
            right: 0;
            top: 0;
            margin-top: 22px;
            margin-right: 22px;
            color: #000;
        }

        div.count {
            padding: 10px 15px;
        }

        div.result {
            max-height: 300px;
            display: flex;
        }

        ul {
            li:not(:first-child) {
                border-top: 1px solid @grayBorder;
            }

            li {
                &.selected {
                    background-color: @colLightGray;
                }

                div.item {
                    position: relative;
                    width: 305px;
                    box-sizing: border-box;
                    overflow: auto;

                    &:hover{
                        background-color: @colHoverItem;
                        color: #0096dc;
                        cursor: pointer;
                    }

                    input[type=checkbox] + span {
                        display: flex;
                        flex-grow: 1;

                        &:after {
                            position: absolute;
                            right: 15px;
                            top: 7px;
                        }
                    }
                }
            }
        }
    }
}

.cm-select .cm-select-inputwrap.count-visible{
  .selected-count {
    display: block;
  };
}

.min-width-unset{
  >.cm-select {
    min-width: 0;
  }
}

.cm-select .item {
    display: flex;
    padding: 7px 15px 7px 15px;
}

.cm-select .item > span, .cm-select .item > gmt-checkbox, .cm-select .item > gmt-checkbox > span {
    display: flex;
    flex-grow: 1;
}

