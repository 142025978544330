/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 25.8.2014, 10:09:28
    Author     : Vojtěch Beneš <vojtech.benes@clevermaps.cz>
*/
@import "mixins.less";


table.layout {
    width: 100%;

    td.column-33 {
        width: 33%;
        padding: 0;
        border-width: 0;
        vertical-align: top;
    }

    td.column-50 {
        width: 50%;
        padding: 0;
        border-width: 0;
        vertical-align: top;
    }
}

.blue .icon-count {
  >i {
    color: @colLightBlue;
  }
}
.icon-count {
    display: inline-block;
    width: 26px;
    text-align: center;
    position: relative;
    font-size: 1.1em;

    .number {
        display: block;
        position: absolute;
        width: 26px;
        text-align: center;
        line-height: 26px;
    }

    >i {
        color: @colGainsboro;
        font-size: 1.1em;

        &.fa-square-o {
            font-size: 2.3em;
        }
    }
}

table:not(.layout):not(.picker__table) {
    width: 100%;
    height: 100%;
    border-collapse: collapse;

    tbody {

        &:not(.hover) tr:hover {
            background-color: @colHoverItem;
        }

        &.hover:hover {
            background-color: @colHoverItem;
        }

        tr.not-hover:hover {
            background-color: initial;
        }

        td.cell-max-height {
            height: 100%;
        }

        td.clickable {
            font-weight: bold;
            cursor: pointer;
        }

        tr {
          &.disabled {
            .disable();
          }
        }
    }

    thead {
      &.sticky {
        tr td, tr th {
          border: none;
          position: sticky;
          top: 0;
          z-index: 10;
          box-shadow: inset -1px 0 0 @grayBorder;

          &:last-child {
            box-shadow: none;
          }
        }

        tr:nth-of-type(2) {

          td, th {
            top: 35px;
            box-shadow: inset -1px 0 0 @grayBorder, inset 0 1px 0 @grayBorder;

            &:last-child {
              box-shadow: inset 0 1px 0 @grayBorder;
            }
          }
        }

      }

      td, th {
        background-color: @headerBackground;
        text-align: center;

        a.sortBy {
          cursor: pointer;
          color: @colBaseFill;

          &.selected {
            color: @colLightBlue;
          }
        }
      }
    }

    tfoot {
        td, th {
            border-top: 1px solid @grayBorder;
        }
    }

    th {
        color: @colBaseFill;
        text-transform: uppercase;
        font-weight: normal;

        a.sortBy {
            cursor: pointer;
            color: @colBaseFill;

            &.selected {
                color: @colLightBlue;
            }
        }

        &.low {
            padding: 5px;
            text-align: center;
        }
        &.nowrap {
            white-space: nowrap;
        }
        &.column-8 {
            width: 8%;
        }
    }

    td a {
        //text-transform: uppercase;
    }
    td, th {
        position: relative;
        padding: 10px 10px;
        border-left: 1px solid @grayBorder;
        border-right: 1px solid @grayBorder;

        &.min {
          width: 1%;
          white-space: nowrap;
        }

        &:first-child {
            border-left-width: 0;
        }

        &:last-child {
            border-right-width: 0;
        }

        &.content-loading {
            min-width: 0;
            min-height: 0;

            .loading-spinner {
                margin: 0;

                i {
                    font-size: 1em;
                }
            }
        }

        &.number {
          text-align: right;
        }
    }
}

.table-top-container{
  .flexbox-container();
  margin: 16px 0px;
  justify-content: space-between;
  position: relative;

  .page-count-select {
      flex: 1;
      justify-content: flex-end;
      .flexbox-container();

      > span {
        height: 25px;
        line-height: 25px;
        padding-right: 5px;
      }
  }
}

.table {
    width: 100%;
    height: 100%;
    display: table;

    .action-cell {
        display: table-cell;
        position: absolute;
        padding: 10px 0;
        margin-left: -40px;
        width: 40px;
    }

    .row {
        display: table-row;
        //line-height: 25px; Problem with multiline cells
    }

    .cell {
        display: table-cell;
        color: black;
        position: relative;
        vertical-align: middle;
        height: 45px;

        &.cell-max-height {
            height: 100%;
        }

        &.min {
          width: 1%;
          white-space: nowrap;
        }

        &:not(.fill) {
          padding: 10px 10px;
        }

        &:not(:last-child) {
            border-right: 1px solid @grayBorder;
        }

        &.number {
            text-align: right;
        }

        &.content-loading {
            min-width: 0;
            min-height: 0;

            .loading-spinner {
                margin: 0;

                i {
                    font-size: 1em;
                }
            }
        }

        &.disabled,
        &.disabled a,
        &.disabled span,
        .disabled,
        .disabled span,
        .disabled a {
          .disable();
        }
    }

    .row-group {
        display: table-row-group;

        &:not(.hover) .row:hover, &.hover:hover, &.hover.hovered {
            background-color: @colHoverItem;

            a.cell {
                color: @colLightBlue;

                &:hover {
                    color: @colDimGray;
                }
            }
        }
    }

    .head-group {
        display: table-header-group;


        &.sticky .cell {
          position: sticky;
          top: 0;
          z-index: 10;
        }

        .cell {
            background-color: @headerBackground;
            text-align: center;
            color: @colBaseFill;
            text-transform: uppercase;
            font-weight: normal;

            a.sortBy {
                cursor: pointer;
                color: @colBaseFill;
                white-space: nowrap;

                &.selected {
                    color: @colLightBlue;
                }
            }
        }
    }
}

.fill-content {
  position:relative;
  height:100%;
  width:100%;

  .content-wrap {
    width:100%;
    height:100%;
    padding:10px;
    display: flex;
    vertical-align: middle;
    align-items:center;

    .number {
        flex: 1;
        text-align: right;
    }
  }
}

.cell--flex__container {
  display: flex;
  flex-direction: row;
}

.cell--flex__container__main {
  flex: 2 1 auto;
  align-self: center;
}

.cell--flex__container_side {
  flex: 1 0 auto;
  text-align: right;
  align-self: center;
}

