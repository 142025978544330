/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 26.8.2014, 16:35:51
    Author     : Vojtěch Beneš <vojtech.benes@clevermaps.cz>
*/
@import "mixins.less";


.cm-is-dragover div#content.cm-dragzone-active .detail{
  margin: 26px;
  padding: 0px;
  border: @colDeepBlue dashed 4px;
  box-sizing: border-box;
}


div#content {
    .detail {
      .flex1();
      display: flex;
      flex-direction: column;

        .cadastre-invalid, .data-ended, .no-opinion, .title-blocked {
            color: #B11600;
        }

        .all-opinions {
            color: #00A300;
        }


        margin: 26px;
        padding: 4px;

        .case-status {
            padding: 10px 0;
            color: @colBaseFill;
            font-size: 1.3em;
            font-weight: normal;
            span {
              margin-right: 50px;
            }
        }

        .case-top {
            display: flex;

            .subject {
                padding: 0;

                >div {
                    &:not(:first-child) {
                        border-top: 1px solid @grayBorder;
                    }
                    padding: 20px;

                    >legend {
                        margin-top: -30px;
                        margin-bottom: 20px;
                    }
                }
            }

            .attachments-flex {
              flex: 0 0 300px;
              margin-left: 20px;
            }
        }

        div.edit {
            text-align: right;
            text-transform: uppercase;
        }

        .load-next {
            text-align: right;
            margin-right: 15px;
        }

        tbody:not(:last-child) {
            tr.current-parcel:last-child td {
                border-bottom: 1px solid @grayBorder;
            }
        }

        tbody:first-child {
            tr th.bordered-top {
                border-top-width: 0px;
            }
        }

        tbody {
            tr.current-parcel.first td {
                border-top: 1px solid @grayBorder;
            }
        }
    }
}
