.cm-checkbox {
  position: relative;
  cursor: pointer;
  user-select: none;

  .checkmark {
    position: relative;
    display: inline-block;
    height: 15px;
    width: 15px;
    flex-shrink: 0;
  }

  .checkmark:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 15px;
    width: 15px;
  }

  .checkmark:not(.switchselect):not(.multiselect):before {
    border: 1px solid #ddd;
    background-color: #fff;
  }

  [type=checkbox]:not(:checked) ~ .checkmark.cm-input-icon-disabled .plus-sign {
    &:before, &:after {
      border-color: #FF9494;
    }
  }

  [type=checkbox]:not(:checked) ~ .checkmark .plus-sign {
    &:before {
      position: absolute;
      left: 6px;
      top: 6px;
      width: 7px;
      height: 7px;
      content: "";
      display: block;
      border: solid #0096dc;
      border-width: 3px 0 0 3px;
    }

    &:after {
      position: absolute;
      left: 2px;
      top: 2px;
      width: 7px;
      height: 7px;
      content: "";
      display: block;
      border: solid #0096dc;
      border-width: 0 3px 3px 0;
    }
  }

  [type=checkbox]:not(:checked) ~ .checkmark:not(.half-state):not(.switchselect):not(.multiselect):not(.cm-input-icon-disabled):hover:before,
  [type=checkbox]:checked ~ .checkmark:not(.switchselect):not(.multiselect):not(:hover):before,
  [type=checkbox] ~ .checkmark.half-state:not(:hover):before {
    background-color: #0096dc;
    border-color: #0096dc;
  }

  [type=checkbox]:not(:checked) ~ .checkmark:not(.half-state):after {
    opacity: 0;
    transform: scale(0) rotate(45deg);
  }

  [type=checkbox]:checked ~ .checkmark:after {
    opacity: 1;
    transform: scale(1) rotate(45deg);

    left: 5px;
    top: 2px;
    width: 5px;
    height: 10px;
    border-width: 0 3px 3px 0;
  }

  .checkmark:after {
    content: "";
    display: block;
    position: absolute;
    border: solid white;
    transition: all 0.2s ease;
    border-width: 0 0 3px 0;
  }

  .half-state:after {
    left: 3px;
    top: 3px;
    width: 9px;
    height: 6px;
    border-width: 0 0 3px 0;
  }

  .checkmark.multiselect:after,
  .checkmark.switchselect:after,
  .checkmark:hover:after {
    border-color: #0096dc;
  }
}
