/*
    Created on : 9.10.2015, 15:34:55
    Author     : Vojtěch Beneš <vojtech.benes@clevermaps.cz>
*/
body.mod-dimap div#content {
  .property-owned-inside {
    color: #70C320;
  }
  .property-owned-outside {
    color: #3B5B94;
  }
  .property-others-inside {
    color: #E16149;
  }
  .property-others-outside {
    color: #000000;
  }

  span.list-check {
    position: absolute;
    padding: 10px 0;
    margin-left: -20px;
    width: 20px;
  }
}
