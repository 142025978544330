@import "mixins.less";

.hover:hover {
  background-color: @colHoverItem;
}

.hover:hover .disabled,
.hover:hover .disabled a{
  color: @black;
}
