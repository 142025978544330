.cm-map-infobox .content.measure-module{
  ul{
    text-align: center;
  }

  ul li {
    float: none;
    display: inline-block;
  }
}
