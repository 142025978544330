@nav-top-margin: 3px;

.nav-item{
  &:not(:first-child) {
    margin-left: -1px;
  }

  &.active:not(:first-child) {
    margin-left: -1px;
    .nav-link{
      margin-left: 0px;
    }
  }
}

/* Tabs panel */
.tabbable-panel {
  border:1px solid #eee;
  padding: 10px;
}

/* Default mode */
.tabbable-line > .nav-tabs {
  border: none;
  border-bottom: 1px solid @nav-tabs-link-active-border-color;
}
.tabbable-line > .nav-tabs > li {
}
.tabbable-line > .nav-tabs > li > a {
  margin-right: 0;
  color: @black;
  background: @colLightGray;
  text-decoration: none;
}

.tabbable-line > .nav-tabs > li:hover {
  border-bottom: @nav-top-margin solid lighten(@colLightBlue, 50%);
}

.tabbable-line > .nav-tabs > li:hover > a {
  background: none !important;
}

.tabbable-line > .nav-tabs > li.active {
  border-bottom: @nav-top-margin solid @colLightBlue;
  position: relative;
}

.tabbable-line > .nav-tabs > li.active > a {
  border-top-color: @colLightBlue;
  background: @white;
}

.tabbable-line > .tab-content {
  margin-top: -@nav-top-margin;
  padding: 15px 0;
}

/* Below tabs mode */

.tabbable-line.tabs-below > .nav-tabs > li {
  border-top: @nav-top-margin solid transparent;
}
.tabbable-line.tabs-below > .nav-tabs > li > a {
  margin-top: 0;
}

.tabbable-line.tabs-below > .nav-tabs > li:hover {
  border-bottom: 0;
  border-top: @nav-top-margin solid lighten(@colLightBlue, 50%);
}

.tabbable-line > .nav-tabs > li.disabled{
  &:hover {
    border-top: @nav-top-margin solid lighten(@colSilver, 10%);
  }

  a {
    color: @colSilver;
    cursor: not-allowed
  }
}

.tabbable-line.tabs-below > .nav-tabs > li.active {
  margin-bottom: -1px;
  border-bottom: 0;
  border-top: @nav-top-margin solid @colLightBlue;
}
.tabbable-line.tabs-below > .tab-content {
  margin-top: 25px;
  padding: 15px 0;
}
