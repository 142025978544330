/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/* 
    Created on : 19.3.2015, 17:09:22
    Author     : Vojtěch Beneš <vojtech.benes@clevermaps.cz>
*/

div#content {
    .templates {
        .actions-template {
            >* {
                margin-right: 10px;
                margin-top: 20px;
            }
        }
        
        i.fa-file-o {
            margin-right: 5px;
        }
        
        a.fa-times {
            color: black;
            margin-left: 5px;
        }
    }
}