@import "mixins.less";

.cm-map-infobox .tools {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: center;

    li {
      cursor: pointer;
      display: inline-block;
      height: 2.5rem;
      line-height: 2.5rem;
      text-align: center;
      vertical-align: top;
      width: 2.5rem;

      &:hover, &.active {
          color: @colAzureBlue;
      }
    }
  }

  .content{
    padding: 5px;
  }
}

.tools.leaflet-bar button:first-of-type {
    border-top-left-radius: @defaultBorderRadius;
    border-bottom-left-radius: @defaultBorderRadius;
    border-top-right-radius: 0px;
}

.tools.leaflet-bar button:last-of-type {
    border-top-right-radius: @defaultBorderRadius;
    border-bottom-right-radius: @defaultBorderRadius;
    border-bottom-left-radius: 0px;
}

.tools.leaflet-bar button, .tools.leaflet-bar button:hover {
    border-bottom: none;
    border-right: 1px solid #ccc;
    cursor: pointer;
}

div#content #map-window .tools.leaflet-bar button {
  width: 30px;
  height: 30px;
  line-height: 30px;

  &:hover, & i.fa.active {
    color: @colAzureBlue;
  }

  i.cuzk, span.cuzk {
    display:block;
    height: 16px;
    background-position-y: center;
    background-position-x: center;
  }
}
