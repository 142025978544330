.is-uppercase {
  text-transform: uppercase;
}

.is-pulled-right {
  float: right;
}

.custom-loop( @base-value:@n; @n; @unit: @n; @j: 1; @_s: 0; @step-size: 1; @selector: @n; @property: @b) when not(@n=0) {
  @size : @base-value+@_s;
  @val  : @j*@step-size;
  @{selector}@{val}{
    @{property} : ~"@{size}@{unit}";
  }
  .custom-loop(@base-value ,  (@n - 1), @unit , (@j + 1) ,  (@_s+@step-size) , @step-size,  @selector, @property);
}

/*** padding helpers ***/
/* .pb-5 to .pb-100 by 5px */
.custom-loop( @n: 100 , @base-value:5, @unit: 'px', @property:padding-bottom, @selector: ~".pb-", @step-size: 5);
/* .pl-5 to .pl-100 by 5px */
.custom-loop( @n: 100 , @base-value:5, @unit: 'px', @property:padding-left, @selector: ~".pl-", @step-size: 5);
/* .pr-5 to .pr-100 by 5px */
.custom-loop( @n: 100 , @base-value:5, @unit: 'px', @property:padding-right, @selector: ~".pr-", @step-size: 5);
/* .pt-5 to .pt-100 by 5px */
.custom-loop( @n: 100 , @base-value:5, @unit: 'px', @property:padding-top, @selector: ~".pt-", @step-size: 5);

/*** margin helpers ***/
/* .mb-5 to .mb-100 by 5px */
.custom-loop( @n: 100 , @base-value:5, @unit: 'px', @property:margin-bottom, @selector: ~".mb-", @step-size: 5);
/* .ml-5 to .ml-100 by 5px */
.custom-loop( @n: 100 , @base-value:5, @unit: 'px', @property:margin-left, @selector: ~".ml-", @step-size: 5);
/* .mr-5 to .mr-100 by 5px */
.custom-loop( @n: 100 , @base-value:5, @unit: 'px', @property:margin-right, @selector: ~".mr-", @step-size: 5);
/* .mt-5 to .mt-100 by 5px */
.custom-loop( @n: 100 , @base-value:5, @unit: 'px', @property:margin-top, @selector: ~".mt-", @step-size: 5);
/* .m-5 to .m-100 by 5px */
.custom-loop( @n: 100 , @base-value:5, @unit: 'px', @property:margin, @selector: ~".m-", @step-size: 5);
