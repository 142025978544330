/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 1.9.2014, 12:25:44
    Author     : Vojtěch Beneš <vojtech.benes@clevermaps.cz>
*/

.content-loading {
    @smallLoaderWidth: 9px;
    @largeLoaderWidth: 30px;

    pointer-events: none;
    min-height: 60px;
    min-width: 60px;
    box-sizing: content-box;
    text-align: center;

    >* {
        opacity: 0.5;
        box-sizing: border-box;
    }

    >.loader {
      order: 1;
      opacity: 1;
      margin-left: auto;
      margin-right: auto;
      width: @largeLoaderWidth;

      span.loading-spinner {
          display: inline-block;
      }
    }

    &.small-spinner {
        min-height: inherit;
        min-width: 0.9em;
        display: inherit;

        span.loading-spinner {
            border-width: 2px;
            margin: 0;
            height: @smallLoaderWidth;
            width: @smallLoaderWidth;
            position:  relative;
        }

        > .loader {
            width: @smallLoaderWidth;
        }
    }

    &.button, &.button-loading {
        min-height: inherit;
        min-width: 0.9em;
        > .loader {
            float: right;
            margin: 0px 0px 0px 6px;
            width: @smallLoaderWidth;

            > span.loading-spinner {
                border: 2px solid @colBaseFill;
                border-right-color: transparent;
                margin: 0px;
                height: @smallLoaderWidth;
                width: @smallLoaderWidth;
                position:  relative;
            }
        }
    }
}

@-webkit-keyframes rotate-forever {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes rotate-forever {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotate-forever {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
span.loading-spinner {
  -webkit-animation-duration: 0.5s;
  -moz-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-iteration-count: infinite;
  -moz-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-name: rotate-forever;
  -moz-animation-name: rotate-forever;
  animation-name: rotate-forever;
  -webkit-animation-timing-function: linear;
  -moz-animation-timing-function: linear;
  animation-timing-function: linear;
  height: 30px;
  width: 30px;
  border: 4px solid @colAzureBlue;
  border-right-color: @colDeepBlue;
  border-radius: 50%;

  display: none;
  position: absolute;
  margin: 10px 0;
  z-index: 1;
};
