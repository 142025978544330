/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 29.9.2014, 17:00:36
    Author     : Vojtěch Beneš <vojtech.benes@clevermaps.cz>
*/
@import "mixins.less";

.block-label {
  display: inline-block;
  background-color: @colSilverChalice;
  padding: 2px 10px;
  line-height: 20px;
  border-top-left-radius: @defaultBorderRadius;
  border-top-right-radius: @defaultBorderRadius;
  font-weight: bold;
  text-transform: uppercase;
  color: #fff;
  box-sizing: content-box;

  &:hover {
      color: #fff;
  }

  .arrow{
    top: 3px;
    margin-left: 16px;
    left: 0;
    .transform(rotate(0));

    &:before, &:after {
      background-color: transparent;
      width: 3px;
      height: 8px;
      display: inline-block;
      position: absolute;
      border-bottom: 10px solid white;
      top: 0;
      left: 0;
      .transform(rotate(0));
    }

    &:before{
      .transform(rotate(-135deg));
    }

    &:after {
      .transform(rotate(135deg));
    }

    &.active{
      &.arrow {
        .transform(rotate(0));
        .transform(translate(0, -6px));
      }

      &:before{
        .transform(rotate(-45deg));
      }

      &:after{
        .transform(rotate(45deg));
      }
    }
  }
}

.bottom-corner {
    background-color: @colSilverChalice;
    position: absolute;
    width: 5px;
    height: 5px;
    z-index: 0;
}

.block-ellipsis {
    background-color: #fdfdfd;
    color: black;
    text-align: center;
    cursor: pointer;
    position: relative;
}

.arrow {
  width: 11px;
  height: 8px;
  display: inline-block;
  position: relative;
  bottom: -5px;
  left: -10px;
  transition: 0.4s ease;
  margin-top: 2px;
  text-align: left;
  transform: rotate(45deg);
  float: right;

  &:before, &:after {
    position: absolute;
    content: '';
    display: inline-block;
    width: 12px;
    height: 3px;
    background-color: #fff;
    transition: 0.4s ease;
  }

  &:after {
    position: absolute;
    .transform(rotate(90deg));
    top: -5px;
    left: 5px;
  }
}
